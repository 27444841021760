<template>
  <v-container :id="wasteRule.slug" class="custom-fix-margin-top px-0 py-12">
    <v-row>
      <v-col cols="12" class="d-flex flex-row justify-center">
        <div class="d-flex align-center">
          <H2Number
            :number="wasteRule.title_has_number ? `${wasteRule.title_number}` : ''"
            :title="`${wasteRule?.title}`" />
        </div>
      </v-col>

      <v-col cols="12" class="mb-6">
        <div class="custom-subtitle d-flex justify-center text-center">
          {{ wasteRule.subtitle }}
        </div>

        <span
          class="d-flex justify-center text-center mt-3"
          v-html="sanitizeHtml(wasteRule.text_content)" />
      </v-col>

      <v-col
        v-for="(allowedAndNotAllowed, index) of [
          wasteRule.fraction.allowed_items,
          wasteRule.fraction.disallowed_items,
        ]"
        :key="index"
        cols="12"
        sm="6">
        <CdeCard class="fill-height">
          <v-card-item class="pa-5">
            <v-card-title class="custom-bold">
              {{
                allowedAndNotAllowed === wasteRule.fraction.allowed_items
                  ? wasteRule.allowed_title
                  : wasteRule.disallowed_title
              }}
            </v-card-title>
          </v-card-item>

          <v-divider />

          <v-row class="mb-4 mt-3">
            <v-col
              v-for="wasteListItem of allowedAndNotAllowed"
              :key="wasteListItem"
              class="pa-2"
              :cols="
                allowedAndNotAllowed?.length && allowedAndNotAllowed?.length > 14 ? '6' : '12'
              ">
              <v-card-text class="pa-0">
                <v-row dense>
                  <v-col cols="1" class="pa-0">
                    <v-icon
                      v-if="allowedAndNotAllowed === wasteRule.fraction.allowed_items"
                      class="mx-2"
                      color="success">
                      mdi-check
                    </v-icon>
                    <v-icon v-else class="mx-2" color="error">mdi-close</v-icon>
                  </v-col>
                  <v-col
                    cols="11"
                    class="pl-4 pl-xl-0 py-0"
                    :class="{
                      'ml-xl-n8 ml-xxl-n12':
                        allowedAndNotAllowed?.length && allowedAndNotAllowed?.length < 15,
                    }">
                    <div class="pa-0 ma-0">
                      {{ wasteListItem }}
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-col>
          </v-row>
        </CdeCard>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup lang="ts">
import type { WasteRule } from '~/types/types'
import { sanitizeHtml } from '~/util/sanitizer'

const props = defineProps<{
  wasteRule: WasteRule
}>()

const { wasteRule } = toRefs(props)
</script>

<style lang="scss" scoped>
.custom-fix-margin-top {
  margin-top: -6px;
}

.custom-bold {
  font-weight: 700;
}

@media (max-width: 959px) {
  .custom-header {
    max-width: 450px;
    text-align: center;
  }
}
@media (max-width: 600px) {
  .custom-header {
    max-width: 300px;
    text-align: center;
  }
}
</style>
